* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.app {
  display: flex;
  flex-direction: column;
}

.default-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

button {
  background-color: #007bff;
  padding: 10px 14px;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 20px;
}

button:hover {
  background-color: #022208;
  color: #ffff00;
}

.modal-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 400px;
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #333;
}

label {
  margin: 10px 0 5px 0;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
}
